<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <SiteForm :is-sending="isSending" :initial-values="initialValues" @formSubmit="onSubmit" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CREATE_SITE } from '@/store/action-types/sites';
import SiteForm from './SiteForm';

export default {
  name: 'SiteCreate',
  components: {
    SiteForm,
  },
  data() {
    return {
      initialValues: {
        active: true,
        advertiserWhiteList: '',
        category: '',
        url: '',
        moderated: '',
      },
    };
  },
  computed: {
    ...mapState('sites', ['isSending']),
  },
  methods: {
    ...mapActions('sites', [CREATE_SITE]),
    onSubmit(formData) {
      this.CREATE_SITE(formData)
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.new_site_added'),
          });
          this.$router.push({ name: 'sites' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.adding_record_error'),
          });
        });
    },
  },
};
</script>
